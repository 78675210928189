import React, { Component } from "react";
import { DataSearch } from "@appbaseio/reactivesearch";

class SerialNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let urlString = window.location.href;
    let paramString = urlString.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    for (let pair of queryString.entries()) {
      if (pair[0] === "Serial") {
        this.setState({ value: pair[1] });
      }
    }
  }

  handleChange(value) {
    if (!value) {
      this.props.serialSearchChanged("NOT_ACTIVE");
      return;
    }
    this.props.serialSearchChanged("ACTIVE");
  }
  handleSelected(value) {
    this.props.serialSearchSelected(true);
  }

  render() {
    let { appType, data } = this.props;
    return (
      <div className="diamond-serial-number form-field-wrap">
        <DataSearch
          title={
            appType && appType === "customer" ? "Serial Number" : "Serial #"
          }
          className="form-field"
          showClear
          debounce={250}
          placeholder={
            appType && appType === "customer"
              ? "Start Search..."
              : "Enter Serial"
          }
          // queryFormat="and"
          dataField={data}
          componentId="SerialSearch"
          onValueChange={(value) => {
            this.handleChange(value)
            this.handleSelected(value);
          }}
          onValueSelected={(value) => this.handleSelected(value)}
          iconPosition={appType && appType === "customer" ? "right" : "left"}
          onChange={(value, triggerQuery, event) => {
            this.setState(
              {
                value,
              },
              () => triggerQuery()
            );
          }}
          value={this.state.value}
        />
      </div>
    );
  }
}
export default SerialNumber;
